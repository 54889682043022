import isMobileDevice from 'ismobilejs'
import { Dispatch, SetStateAction } from 'react'

export enum DEFAULT_SHORT_NAME {
  MODEL_3_5_4k = '3.5-4k',
  MODEL_3_5_16k = '3.5-16k',
  MODEL_4_TURBO = '4-turbo',
  MODEL_4_0_8k = '4-8k',
}

export interface UsedLinks {
  url: string
  title?: string
  type?: string
  image?: string
  smallImage?: string
  date?: string
  description?: string
}
//export const isMobile = true
export const isMobile = window ? isMobileDevice(window?.navigator).any : false

const linkImageGetHrefForImage = (node: HTMLElement) => {
  const a = node.parentNode?.querySelector('a')
  return a?.getAttribute('href')
}

export const linkImageonMouserOver = (e: React.MouseEvent<HTMLDivElement>) => {
  const node = e.target as HTMLElement

  if (node.nodeName === 'IMG') {
    const src = node.getAttribute('src')
    if (src && src.toLowerCase().includes('width-840')) {
      node.style.transform = 'scale(7)'
    } else {
      node.style.transform = 'scale(3)'
    }
  }
}

export const linkImageOnMouseOut = (e: React.MouseEvent<HTMLDivElement>) => {
  const node = e.target as HTMLElement
  if (node.nodeName === 'IMG') {
    node.style.transform = 'scale(1)'
  }
}

export const linkImageOnMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
  const node = e.target as HTMLElement
  if (node.nodeName === 'IMG') {
    const href = linkImageGetHrefForImage(node)
    if (href) {
      window.open(href, '_blank')
    }
  }
}

export const replaceLinksWithCallback = (
  input: string,
  usedLinks: UsedLinks[],
  setResult: Dispatch<SetStateAction<string>>,
) => {
  const linkMatches = input.match(/\[\^([0-9]+)\^*]/g)
  if (linkMatches) {
    setResult(replaceLinks(input, usedLinks))
  }
}

export const replaceLinks = (input: string, usedLinks: UsedLinks[]) => {
  const linkMatches = input.match(/\[\^([\^0-9, ]+)]/g)

  if (linkMatches) {
    let newInput = input

    linkMatches.forEach(fMatch => {
      fMatch.split(',').forEach(match => {
        const linkNumber = match.match(/([0-9]+)/g)?.[0]

        if (!linkNumber) {
          return
        }
        const link = linkNumber && usedLinks[parseInt(linkNumber) - 1]
        if (link) {
          newInput = newInput.replace(match, ` ([Link ${linkNumber}](${link.url} "__link: ${linkNumber}"))`)
        } else {
          // if we don't have a link, remove the link
          newInput = newInput.replace(match, ``)
        }
      })
    })
    return newInput
  }
  return input
}
